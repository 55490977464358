<template>
	<div>
		<header-slot />
		<reports-table />
	</div>
</template>

<script>
import ReportsTable from "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue"

export default {
	components: {
		ReportsTable,
	},
}
</script>
